const NonFieldErrorBlock = ({ errorMessages }: { errorMessages: Array<string | undefined> }) => {
    return errorMessages.length && errorMessages.find(message => !!message) ? (
        <div className="rounded-md bg-red-100 p-4">
            <div className="flex items-center">
                <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-500">
                        <use xlinkHref="/svg-sprite.xml#exclamation-solid" />
                    </svg>
                </div>
                <div className="ml-3">
                    <div className="text-medium text-red-500">
                        {errorMessages.map((message, index) => (
                            <div key={index}>{message}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export { NonFieldErrorBlock };
