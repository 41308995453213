import { URI_LOGIN } from "../../constants/urls";

export const dropdownHeight: number = 300;

export const isNotEmailValid = (value: string) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
export const isNotImageValid = (value: string) => !/\.(jpe?g|png|gif|bmp)$/i.test(value);

export const getErrorsFromErrorObject = (error: { [x: string]: any }) => {
    let errorMessages: any[] = [];

    for (let key of Object.keys(error)) {
        switch (key) {
            case "message":
                errorMessages.push(error[key]);
                break;
            case "detail":
                errorMessages.push(error[key]);
                break;
            case "success":
                break;
            default:
                let errorMsg: string | string[];

                if (Array.isArray(error[key])) {
                    errorMsg = [];

                    error[key].forEach((el: { [x: string]: any }) => {
                        if (typeof el == "string") {
                            //@ts-ignore
                            errorMsg.push(el);
                        } else if (typeof el === "object") {
                            for (let elKey of Object.keys(el)) {
                                //@ts-ignore
                                errorMsg.push(`${elKey}: ${el[elKey]}`);
                            }
                        }
                    });

                    errorMsg = errorMsg.toString();
                    errorMessages.push(`${key}: ${errorMsg}`);
                } else if (typeof error[key] === "string") {
                    errorMsg = error[key];
                    errorMessages.push(`${key}: ${errorMsg}`);
                } else if (typeof error[key] === "object") {
                    const errors = getErrorsFromErrorObject(error[key]);
                    errorMessages = [...errorMessages, ...errors];
                }
        }
    }
    return errorMessages;
};

export const getErrorMessages = (error: { response: { data: any }; message: any }) => {
    let errorMessages: any[] = [];

    if (error?.response?.data) {
        if (typeof error.response.data === "object") {
            const errors = getErrorsFromErrorObject(error.response.data);
            errorMessages = [...errorMessages, ...errors];
        }
    }

    if (error?.message && !error?.response?.data) {
        errorMessages.push(error?.message);
    }

    if (typeof error === "string") {
        errorMessages.push(error);
    }

    return errorMessages;
};

export const getMaxErrorMessage = (max: number) =>
    `Ensure this field has no more than ${max} characters.`;

export const isEmpty = (obj: object) => {
    return Object.keys(obj).length === 0;
};

export const getEncodedURlErrorMessage = (url: string, error: string) => {
    return `${url}?error=${Buffer.from(error).toString("base64")}`;
};
export const getDecodedURLErrorMessage = (error: string) =>
    Buffer.from(error, "base64").toString("ascii");

export const determineDistanceFromTop = (selector: string) => {
    let dropdownSelector = document.querySelector(`#${selector}`);
    let distanceToTop,
        findDifferencies,
        isListCase = false;
    if (dropdownSelector) {
        distanceToTop = dropdownSelector.getBoundingClientRect().top;
        findDifferencies = window.innerHeight - distanceToTop;
        if (findDifferencies > dropdownHeight) {
            isListCase = true;
        } else {
            isListCase = false;
        }
    }

    return isListCase;
};
