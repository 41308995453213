import { FormEvent, useState } from "react";
import { Eye, EyeClosed } from "phosphor-react";

import { maxLengthForInput } from "constants/index";

export default function LoginForm({
    csrfToken,
    errorMessage,
    onSubmit,
    className = ""
}: {
    csrfToken: any;
    errorMessage: string;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    className?: string;
}) {
    const [showPassword, setShowPassword] = useState(false);

    const handleVisibilityPassword = (e: React.MouseEvent<HTMLElement>) => {
        setShowPassword(!showPassword);
    };

    return (
        <form
            onSubmit={onSubmit}
            className={className}
            method="post"
            action="/api/auth/callback/credentials"
        >
            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
            <div className="mt-6">
                <label htmlFor="username" className="block text-secondaryDark">
                    Username
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="username"
                        id="username"
                        required
                        autoFocus
                        className="appearance-none form-control"
                    />
                </div>
            </div>
            <div className="show_hide_password">
                <label htmlFor="password" className="block text-secondaryDark">
                    Password
                </label>
                <div className="relative w-full mt-1">
                    <div className="absolute inset-y-0 right-0 flex items-center px-2">
                        <span
                            className="icon rounded px-2 py-1 text-sm text-secondaryDark font-mono cursor-pointer"
                            data-testid="button"
                            onClick={handleVisibilityPassword}
                        >
                            {showPassword ? (
                                <EyeClosed data-testid="eyeOffIcon" className="h-6 w-6" />
                            ) : (
                                <Eye data-testid="eyeIcon" className="h-6 w-6" />
                            )}
                        </span>
                    </div>
                    <input
                        data-testid="password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        required
                        className="appearance-none form-control"
                        maxLength={maxLengthForInput}
                    />
                </div>
                {errorMessage && <p className="my-1 text-sm text-error">{errorMessage}</p>}
            </div>

            <div>
                <button type="submit" className="btn-primary justify-center">
                    Sign in
                </button>
            </div>
        </form>
    );
}
