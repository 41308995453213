import React, { useEffect, useState } from "react";
import {
    getCsrfToken,
    getProviders,
    getSession,
    signIn,
    signOut,
    useSession
} from "next-auth/react";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import getConfig from "next/config";
import { deleteCookie, getCookies } from "cookies-next";

import Layout from "components/ClearLayout";
import LoginForm from "components/login/LoginForm";
import FooterMinimal from "components/layout/FooterMinimal";
import { AuthProviders } from "constants/index";
import { URI_BASE, URI_LOGIN, URI_RESET_PASSWORD } from "constants/urls";
import { NonFieldErrorBlock } from "components/common/form/NonFieldErrorMessage";
import { UiConfigType } from "types/index";
import { getDecodedURLErrorMessage } from "utils/form/form-utils";
import { ForaLogo } from "components/common/v2/ForaLogo";

const { publicRuntimeConfig } = getConfig();
const showUserPasswordLogic = publicRuntimeConfig.showUserPasswordLogic === "true";

enum TypeLogin {
    Email,
    GoogleAuth
}

export type LoginErrorType = {
    error: string;
    ok: boolean;
    status: number;
    url: string;
};

export default function Login({
    csrfToken,
    uiConfig
}: {
    providers?: typeof getProviders;
    csrfToken?: typeof getCsrfToken;
    uiConfig?: UiConfigType;
}) {
    const router = useRouter();
    let callbackUrl = process.env.NEXT_PUBLIC_GOOGLE_CALLBACK_URL || URI_BASE;
    const backLink = router.query?.from ? router.asPath.replace(`${URI_LOGIN}?from=`, "") : "";
    const [errorMsg, setErrorMsg] = useState("");
    const [errorSignIn, setErrorSignIn] = useState<Array<string>>([""]);
    const { data: session } = useSession();
    const [typeLogin, setTypeLogin] = useState<TypeLogin>(TypeLogin.GoogleAuth);

    callbackUrl = `${callbackUrl}${backLink}`;

    useEffect(() => {
        const errors: string = router.query.error as string;
        if (router.query.error) {
            const parsedBase64errors = getDecodedURLErrorMessage(errors);
            setErrorSignIn([parsedBase64errors]);
        }
    }, [router?.isReady]);

    useEffect(() => {
        if (session) {
            router.push(URI_BASE);
        }
    }, [session]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        localStorage.clear();

        const body = {
            username: event.currentTarget.username.value?.toLowerCase(),
            password: event.currentTarget.password.value
        };

        // @ts-ignore
        const data: LoginErrorType = await signIn("simple-login", {
            ...body,
            callbackUrl,
            redirect: false
        });
        if (!data.error) {
            router.push(URI_BASE);
        } else {
            setErrorMsg(data.error);
        }
    };

    const handleGoogleSubmit = async () =>
        await signIn(AuthProviders.GOOGLE, {
            callbackUrl
        });
    const handleSignOut = () => {
        const cookies = Object.keys(getCookies());
        cookies.forEach(key => deleteCookie(key));
        localStorage.clear();
        signOut();
    };

    return (
        <Layout title="Login">
            <div className="flex w-full">
                <div className="h-screen hidden lg:flex sticky top-0 relative lg:w-[43.75%] lg:max-w-[840px]">
                    <Image
                        src="/img/bg-onboarding.jpg"
                        alt="Fora Travel"
                        className="h-full w-full object-cover"
                        fill
                        loading={"eager"}
                    />
                    <div className="absolute top-0 left-0 p-8">
                        <ForaLogo
                            className="fill-nav-bgLight w-[79px] h-6"
                            data-testid="logo-image-id"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center flex-1">
                    <div className="px-6 m-auto max-w-[486px]">
                        <div className="bg-white shadow-card border border-stroke px-5 py-8 lg:p-16">
                            <div className="flex justify-center mb-4">
                                <ForaLogo className="fill-[#131313] mx-auto w-[50px] h-[15px]" />
                            </div>
                            <h1 className="text-center text-displayLargeMobile lg:text-title font-medium mb-4 lg:mb-1">
                                Welcome to <br />
                                Fora’s Advisor Portal
                            </h1>
                            <p className="text-center text-secondaryDark">
                                This site is for use by Fora Advisors only. If you need assistance,
                                please contact{" "}
                                <a
                                    href={`mailto:${uiConfig?.supportEmail}`}
                                    className="hover:underline"
                                >
                                    {uiConfig?.supportEmail}
                                </a>
                                .
                            </p>
                            <div className="lg:mt-4">
                                <NonFieldErrorBlock errorMessages={errorSignIn} />
                            </div>

                            {typeLogin === TypeLogin.GoogleAuth && (
                                <div className="space-y-6">
                                    <div className="text-center">
                                        {session ? (
                                            <>
                                                Signed in as {session?.user?.email} <br />
                                                <button onClick={() => handleSignOut()}>
                                                    Sign out
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={handleGoogleSubmit}
                                                    className="mt-8 font-medium lg:w-full btn-primary justify-center"
                                                >
                                                    Sign in with your Fora email
                                                </button>
                                            </>
                                        )}
                                    </div>
                                    {showUserPasswordLogic && (
                                        <div className="text-left text-sm">
                                            <span>Not an advisor?</span>{" "}
                                            <a
                                                className="font-normal text-indigo-600 hover:text-indigo-500"
                                                href="#"
                                                onClick={async e => {
                                                    e.preventDefault();
                                                    setTypeLogin(TypeLogin.Email);
                                                }}
                                            >
                                                Sign in here.
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}
                            {typeLogin === TypeLogin.Email && (
                                <>
                                    <div className="text-center">
                                        <a
                                            href="#"
                                            className="mt-8 w-full btn-primary justify-center"
                                            onClick={async e => {
                                                e.preventDefault();
                                                setTypeLogin(TypeLogin.GoogleAuth);
                                            }}
                                        >
                                            Are you an advisor? Sign in here.
                                        </a>
                                    </div>
                                    <div className="relative flex pt-5 items-center">
                                        <div className="flex-grow border-t border-stroke m-2" />
                                        <span className="flex-shrink mx-4 text-secondaryDark text-small">
                                            &nbsp;Or continue with&nbsp;
                                        </span>
                                        <div className="flex-grow border-t border-stroke" />
                                    </div>
                                    <LoginForm
                                        csrfToken={csrfToken}
                                        errorMessage={errorMsg}
                                        onSubmit={handleSubmit}
                                        className="space-y-6"
                                    />
                                    <div className="auth-form__actions flex items-center justify-end mt-6">
                                        <div className="text-sm">
                                            <Link
                                                href={URI_RESET_PASSWORD}
                                                className="text-link hover:text-blue-600"
                                            >
                                                Forgot password
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="py-6 px-2">
                        <FooterMinimal />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const getServerSideProps: GetServerSideProps = async context => {
    const providers = await getProviders();
    const session = await getSession(context);

    if (session) {
        return {
            redirect: {
                destination: URI_BASE,
                permanent: false
            }
        };
    }

    return {
        props: { providers }
    };
};

export async function getInitalProps(context: any) {
    return {
        csrfToken: await getCsrfToken(context)
    };
}
